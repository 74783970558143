import { Children } from "../types/Children";
import { Meal } from "../types/Meal";
import { Training } from "../types/Training";
import { Water } from "../types/Water";
import apiClient, { buildRequestConfig } from "./apiClient";

export const updateChildren = async (children: Children): Promise<any> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/childrens/${children.id}`;
    return await apiClient.put(reuqestUrl, children, config)
        .then((response) => {
            if(response.status === 200) {
                const responseData = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const getChildren = async (childrenId: string): Promise<Children> => {
    const config = buildRequestConfig();
    return await apiClient.get(`/childrens/${childrenId}`, config)
        .then((response) => {
            const responseData: Children = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const deleteChildren = async (childrenId: string): Promise<any> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/childrens/${childrenId}`;
    return await apiClient.delete(reuqestUrl, config)
        .then((response) => {
            if(response.status === 200) {
                const responseData = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            console.log(error);
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const getChildrenMeals = async (childrenId: string, date: string): Promise<Meal[]> => {
    const config = buildRequestConfig();
    return await apiClient.get(`/childrens/${childrenId}/meals?date=${date}`, config)
        .then((response) => {
            const responseData: Meal[] = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const addChildrenMeal = async (childrenId: string, meal: Meal): Promise<Meal> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/childrens/${childrenId}/meals`;
    return await apiClient.post(reuqestUrl, meal, config)
        .then((response) => {
            if(response.status === 201) {
                const responseData: Meal = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            console.log(error);
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const getChildrenWater = async (childrenId: string, date: string): Promise<Water> => {
    const config = buildRequestConfig();
    return await apiClient.get(`/childrens/${childrenId}/waters?date=${date}`, config)
        .then((response) => {
            const responseData: Water = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const addChildrenWater = async (childrenId: string, water: Water): Promise<Water> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/childrens/${childrenId}/waters`;
    return await apiClient.post(reuqestUrl, water, config)
        .then((response) => {
            if(response.status === 201 || response.status === 200) {
                const responseData: Water = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            console.log(error);
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const getChildrenTraining = async (childrenId: string, date: string): Promise<Training> => {
    const config = buildRequestConfig();
    return await apiClient.get(`/childrens/${childrenId}/trainings?date=${date}`, config)
        .then((response) => {
            const responseData: Training = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const addChildrenTraining = async (childrenId: string, training: Training): Promise<Training> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/childrens/${childrenId}/trainings`;
    return await apiClient.post(reuqestUrl, training, config)
        .then((response) => {
            if(response.status === 201 || response.status === 200) {
                const responseData: Training = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            console.log(error);
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}