import { NavLink } from 'react-router-dom';

import Logo from "../../images/logo-fun-vegetables.png";

import { logout } from '../../services/AuthService';

import { RecipesIcon } from '../../icons/RecipesIcon';
import { DashboardIcon } from '../../icons/DashboardIcon';
import { TipsIcon } from '../../icons/TipsIcon';
import { ProfileIcon } from '../../icons/ProfileIcon';

import './Sidebar.scss';

export const Sidebar: React.FC = () => {
    return (  
        <div className="sidebar">
            <div className='logo'>
                <img className='logo-image' src={Logo} alt="" />
            </div>
            <div className='dashboard-pages'>
                <NavLink 
                    className='sidebar-link'
                    to={"/diario-alimentare"}
                >
                    <span className='icon'>
                        <DashboardIcon />
                    </span>
                    <p className="text text-xs">Diario</p>
                </NavLink>
                <NavLink className='sidebar-link' to={"/contest"}>
                    <span className='icon'>
                        <RecipesIcon />
                    </span>
                    <p className="text text-xs">Contest</p>
                </NavLink>
                {/* <NavLink className='sidebar-link' to={"/ricette"}>
                    <span className='icon'>
                        <RecipesIcon />
                    </span>
                    <p className="text text-xs">Ricette</p>
                </NavLink>
                <NavLink className='sidebar-link' to={"/tips"}>
                    <span className='icon'>
                        <TipsIcon />
                    </span>
                    <p className="text text-xs">Tips</p>
                </NavLink> */}
                <NavLink className='sidebar-link' to={"/profilo"}>
                    <span className='icon'>
                        <ProfileIcon />
                    </span>
                    <p className="text text-xs">Profilo</p>
                </NavLink>
            </div>
            <div className='dashboard-exit'>
                <div className='sidebar-link' onClick={logout}>
                    {/* <span className='icon'>
                        <RecipesIcon />
                    </span> */}
                    <p className="text text-xs">Esci</p>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;