import { EmojiOption } from "../../types/SelectOption";
import { InputError } from "./Input";

interface Props {
    error?: string,
    value?: string,
    options: EmojiOption[]
    onSelect: (option: EmojiOption) => void
}

export const InputBeverageSelect: React.FC<Props> = ({
    error,
    value,
    options,
    onSelect 
}) => {

    return <div className='input-beverage-select'>
        <div className='options'>
            {options.map((option, i) =>
                <div key={i} className={`option ${option.value === value ? 'selected' : ''}`} onClick={() => onSelect(option)}>
                    <img src={`images/${option.imagePath}`} />
                    <p className="text-xs">{option.value}</p>
                </div>
            )}
        </div>
        {error && 
            <InputError error={error}/>
        }
    </div>

};
