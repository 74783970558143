interface Props {
    onClick: () => void
}

export const AddChildrenCard: React.FC<Props> = ({onClick}) => {   
    return <div className="children-card add-button" onClick={onClick}>
        <p className='plus'>+</p>
        <div className='text'>
            <p className="font-bold text-large">Aggiungi Bambino/a</p>
        </div>
    </div>
};
