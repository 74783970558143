import React, { InputHTMLAttributes, useEffect } from "react"
import { Button } from "../Button/Button";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    className?: string,
    primaryButtonClassname?: string,
    primaryButtonDisabled?: boolean,
    primaryButtonLoading?: boolean,
    title?: string,
    isOpen: boolean,
    onCloseButtonClick?: () => void
    onPrimaryButtonClick?: (args?: any) => void
}

export const DeleteModal: React.FC<Props> = ({
    className,
    children,
    isOpen,
    primaryButtonClassname,
    primaryButtonDisabled,
    primaryButtonLoading,
    onCloseButtonClick,
    onPrimaryButtonClick
}) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isOpen])

    if (isOpen) {
        return <div className={'modal-wrapper ' + (className ? className : '')} onClick={onCloseButtonClick}>
            <div className="modal-content" onClick={e => { e.stopPropagation(); }}>
                <div className="modal-header">
                    <h2 className="text-medium modal-title">Elimina</h2>
                    <span className="close">
                        <svg onClick={onCloseButtonClick} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer delete-modal-footer">
                    {onPrimaryButtonClick &&
                        <Button
                            className={"delete-button " + (primaryButtonClassname ? primaryButtonClassname : '')}
                            label='Elimina'
                            type="button"
                            isLoading={primaryButtonLoading}
                            disabled={primaryButtonDisabled}
                            onClick={onPrimaryButtonClick}
                        />
                    }
                </div>
            </div>
        </div>
    }

    return <></>

};
