import { EmojiOption, ImageValueOption } from "../types/SelectOption";

// export const imageBaseUrl = 'https://apifunv.danieletotaro.com/images/'
export const imageBaseUrl = 'http://localhost:81/images/'

export const genders = [
    {
        label: 'Femminile',
        value: 'F',
    },
    {
        label: 'Maschile',
        value: 'M',
    }
];

export const recipeCategories = [
    {
        label: 'Antipasti',
        value: '1',
    },
    {
        label: 'Dolci',
        value: '4',
    },
    {
        label: 'Primi Piatti',
        value: '2',
    },
    {
        label: 'Secondi Piatti',
        value: '3',
    }
];

export const recipeDifficults = [
    {
        label: 'Facile',
        value: '1',
    },
    {
        label: 'Media',
        value: '2',
    },
    {
        label: 'Difficile',
        value: '3',
    }
];

export const waterValues = [
    {
        label: '< 1 litro',
        value: '1',
    },
    {
        label: 'Tra 1 e 2 litri',
        value: '2',
    },
    {
        label: '> 2 litri',
        value: '3',
    }
];

export const trainingValues = [
    {
        label: '< 30 min',
        value: '1',
    },
    {
        label: 'Tra 30 e 60 min',
        value: '2',
    },
    {
        label: '> 60 minuti',
        value: '3',
    }
];

export const emojiValues: EmojiOption[] = [
    {
        imagePath: 'sad.png',
        value: 1,
    },
    {
        imagePath: 'confused.png',
        value: 2,
    },
    {
        imagePath: 'smile.png',
        value: 3,
    }
];

export const beverageValues: ImageValueOption[] = [
    {
        imagePath: 'water-glass.png',
        value: '1 Bicchiere',
    },
    {
        imagePath: 'water-2glass.png',
        value: '2 Bicchieri',
    },
    {
        imagePath: 'water-3glass.png',
        value: '3 o più Bicchieri',
    }
];


export const foods = [
    {
        "label": "ACCIUGHE o ALICI",
        "value": 283
    },
    {
        "label": "ACCIUGHE o ALICI SOTT'OLIO",
        "value": 316
    },
    {
        "label": "ACCIUGHE o ALICI SOTTO SALE",
        "value": 320
    },
    {
        "label": "ACETO",
        "value": 397
    },
    {
        "label": "ACQUA",
        "value": 403
    },
    {
        "label": "AGLIO, fresco",
        "value": 16
    },
    {
        "label": "AGLIO, in polvere",
        "value": 17
    },
    {
        "label": "AGNELLO, CARNE MAGRA",
        "value": 282
    },
    {
        "label": "AGRETTI",
        "value": 25
    },
    {
        "label": "ALBICOCCHE",
        "value": 78
    },
    {
        "label": "ALBICOCCHE, disidratate",
        "value": 117
    },
    {
        "label": "AMARENE",
        "value": 79
    },
    {
        "label": "ANACARDI",
        "value": 118
    },
    {
        "label": "ANANAS",
        "value": 80
    },
    {
        "label": "ARACHIDI, fresche",
        "value": 119
    },
    {
        "label": "ARACHIDI, tostate e salate",
        "value": 120
    },
    {
        "label": "ARAGOSTA",
        "value": 326
    },
    {
        "label": "ARANCE",
        "value": 81
    },
    {
        "label": "ARINGA",
        "value": 284
    },
    {
        "label": "ASIAGO",
        "value": 176
    },
    {
        "label": "ASPARAGI",
        "value": 4
    },
    {
        "label": "ASTICE",
        "value": 327
    },
    {
        "label": "AVENA",
        "value": 214
    },
    {
        "label": "AVOCADO",
        "value": 82
    },
    {
        "label": "BACCALA', ammollato",
        "value": 321
    },
    {
        "label": "BACCALA', secco",
        "value": 322
    },
    {
        "label": "BANANA",
        "value": 83
    },
    {
        "label": "BARBABIETOLE ROSSE",
        "value": 6
    },
    {
        "label": "BASTONCINI DI PESCE SURGELATI, MERLUZZO",
        "value": 285
    },
    {
        "label": "BEVANDA A BASE DI COLA",
        "value": 390
    },
    {
        "label": "BEVANDE GASSATE DIETETICHE",
        "value": 391
    },
    {
        "label": "BIETA",
        "value": 26
    },
    {
        "label": "BIGNE'",
        "value": 381
    },
    {
        "label": "BISCOTTI, GENERICO",
        "value": 387
    },
    {
        "label": "BRESAOLA",
        "value": 257
    },
    {
        "label": "BRIE",
        "value": 177
    },
    {
        "label": "BRIOCHE o CROISSANT, VUOTA",
        "value": 372
    },
    {
        "label": "BROCCOLO",
        "value": 13
    },
    {
        "label": "BRODO DI CARNE VARIA",
        "value": 400
    },
    {
        "label": "BRODO DI DADO",
        "value": 401
    },
    {
        "label": "BRODO VEGETALE",
        "value": 402
    },
    {
        "label": "BUDINO AL CIOCCOLATO",
        "value": 377
    },
    {
        "label": "BUDINO ALLA VANIGLIA",
        "value": 378
    },
    {
        "label": "BURRATA",
        "value": 178
    },
    {
        "label": "BURRO",
        "value": 343
    },
    {
        "label": "BURRO DI ARACHIDI",
        "value": 333
    },
    {
        "label": "CACAO AMARO, in polvere",
        "value": 395
    },
    {
        "label": "CACAO DOLCE, in polvere, solubile",
        "value": 396
    },
    {
        "label": "CACIOCAVALLO",
        "value": 179
    },
    {
        "label": "CACIORICOTTA DI CAPRA",
        "value": 180
    },
    {
        "label": "CACIOTTA",
        "value": 181
    },
    {
        "label": "CALAMARO",
        "value": 307
    },
    {
        "label": "CALAMARO SURGELATO",
        "value": 308
    },
    {
        "label": "CAMEMBERT",
        "value": 182
    },
    {
        "label": "CANDITI",
        "value": 356
    },
    {
        "label": "CARAMELLE ALLA FRUTTA",
        "value": 360
    },
    {
        "label": "CARAMELLE DURE",
        "value": 361
    },
    {
        "label": "CARAMELLE MORBIDE, MOU",
        "value": 362
    },
    {
        "label": "CARCIOFI",
        "value": 34
    },
    {
        "label": "CARCIOFI SURGELATI",
        "value": 35
    },
    {
        "label": "CARCIOFINI SOTT'ACETO",
        "value": 47
    },
    {
        "label": "CARCIOFINI SOTT'OLIO",
        "value": 48
    },
    {
        "label": "CARNE DI BOVINO",
        "value": 271
    },
    {
        "label": "CAROTE",
        "value": 7
    },
    {
        "label": "CASTAGNE, arrostite",
        "value": 121
    },
    {
        "label": "CASTAGNE, secche",
        "value": 122
    },
    {
        "label": "CAVALLO, senza grasso visibile",
        "value": 270
    },
    {
        "label": "CAVOLFIORE",
        "value": 11
    },
    {
        "label": "CAVOLI DI BRUXELLES",
        "value": 12
    },
    {
        "label": "CAVOLO CAPPUCCIO",
        "value": 14
    },
    {
        "label": "CECI IN SCATOLA",
        "value": 58
    },
    {
        "label": "CECI, secchi",
        "value": 59
    },
    {
        "label": "CEREALI AGGREGATI",
        "value": 229
    },
    {
        "label": "CERNIA",
        "value": 286
    },
    {
        "label": "CETRIOLI",
        "value": 36
    },
    {
        "label": "CETRIOLINI SOTT'ACETO",
        "value": 49
    },
    {
        "label": "CHEDDAR",
        "value": 183
    },
    {
        "label": "CICORIA",
        "value": 27
    },
    {
        "label": "CILIEGE",
        "value": 84
    },
    {
        "label": "CIOCCOLATO AL LATTE",
        "value": 350
    },
    {
        "label": "CIOCCOLATO BIANCO",
        "value": 351
    },
    {
        "label": "CIOCCOLATO CON NOCCIOLE",
        "value": 352
    },
    {
        "label": "CIOCCOLATO FONDENTE",
        "value": 353
    },
    {
        "label": "CIOCCOLATO GIANDUIA",
        "value": 354
    },
    {
        "label": "CIPOLLE",
        "value": 18
    },
    {
        "label": "CIPOLLE, secche",
        "value": 19
    },
    {
        "label": "CIPOLLINE",
        "value": 20
    },
    {
        "label": "CIPOLLINE SOTT'ACETO",
        "value": 50
    },
    {
        "label": "COCOMERO",
        "value": 85
    },
    {
        "label": "CONFETTI",
        "value": 363
    },
    {
        "label": "CONIGLIO, CARNE MAGRA",
        "value": 281
    },
    {
        "label": "CONO PER GELATO",
        "value": 388
    },
    {
        "label": "COPPA PARMA",
        "value": 258
    },
    {
        "label": "COREGONE",
        "value": 287
    },
    {
        "label": "CORVINA",
        "value": 288
    },
    {
        "label": "COTECHINO (CONFEZIONATO PRECOTTO), bollito",
        "value": 259
    },
    {
        "label": "COTECHINO (CONFEZIONATO PRECOTTO), crudo",
        "value": 260
    },
    {
        "label": "COZZA o MITILO",
        "value": 309
    },
    {
        "label": "CRACKERS INTEGRALI",
        "value": 241
    },
    {
        "label": "CRACKERS NON SALATI",
        "value": 242
    },
    {
        "label": "CRACKERS SALATI",
        "value": 243
    },
    {
        "label": "CRAUTI",
        "value": 15
    },
    {
        "label": "CREMA DI CACAO E NOCCIOLE",
        "value": 355
    },
    {
        "label": "CREME CARAMEL",
        "value": 379
    },
    {
        "label": "CRESCENZA",
        "value": 184
    },
    {
        "label": "CROSTATA CON MARMELLATA",
        "value": 383
    },
    {
        "label": "DATTERI",
        "value": 123
    },
    {
        "label": "DATTERI, freschi",
        "value": 86
    },
    {
        "label": "DENTICE",
        "value": 289
    },
    {
        "label": "DENTICE SURGELATO",
        "value": 290
    },
    {
        "label": "EMMENTHAL",
        "value": 185
    },
    {
        "label": "FAGIOLI IN SCATOLA",
        "value": 60
    },
    {
        "label": "FAGIOLI, secchi",
        "value": 61
    },
    {
        "label": "FAGIOLINI",
        "value": 62
    },
    {
        "label": "FAGIOLINI IN SCATOLA",
        "value": 63
    },
    {
        "label": "FARAONA, COSCIO, senza pelle",
        "value": 272
    },
    {
        "label": "FARAONA, PETTO, senza pelle",
        "value": 273
    },
    {
        "label": "FARINA DI AVENA",
        "value": 222
    },
    {
        "label": "FARINA DI CASTAGNE",
        "value": 138
    },
    {
        "label": "FARINA DI CECI",
        "value": 74
    },
    {
        "label": "FARINA DI COCCO",
        "value": 139
    },
    {
        "label": "FARINA DI FRUMENTO, INTEGRALE",
        "value": 223
    },
    {
        "label": "FARINA DI FRUMENTO, TIPO 0",
        "value": 224
    },
    {
        "label": "FARINA DI FRUMENTO, TIPO 00",
        "value": 225
    },
    {
        "label": "FARINA DI RISO",
        "value": 226
    },
    {
        "label": "FAVE",
        "value": 64
    },
    {
        "label": "FAVE, secche, sgusciate",
        "value": 65
    },
    {
        "label": "FETA",
        "value": 186
    },
    {
        "label": "FETTE BISCOTTATE",
        "value": 375
    },
    {
        "label": "FETTE BISCOTTATE, INTEGRALI",
        "value": 376
    },
    {
        "label": "FICHI",
        "value": 87
    },
    {
        "label": "FICHI D'INDIA",
        "value": 88
    },
    {
        "label": "FICHI, secchi",
        "value": 124
    },
    {
        "label": "FINOCCHIO",
        "value": 37
    },
    {
        "label": "FIOCCHI DI AVENA",
        "value": 230
    },
    {
        "label": "FIOCCHI DI CRUSCA DI GRANO",
        "value": 231
    },
    {
        "label": "FIOCCHI DI LATTE MAGRO",
        "value": 187
    },
    {
        "label": "FIOR DI LATTE",
        "value": 188
    },
    {
        "label": "FIORI DI ZUCCA",
        "value": 38
    },
    {
        "label": "FOCACCIA",
        "value": 246
    },
    {
        "label": "FONTINA",
        "value": 189
    },
    {
        "label": "FORMAGGINO",
        "value": 190
    },
    {
        "label": "FORMAGGIO CREMOSO SPALMABILE, LIGHT",
        "value": 191
    },
    {
        "label": "FORMAGGIO MOLLE DA TAVOLA (TIPO BEL PAESE)",
        "value": 192
    },
    {
        "label": "FORMAGGIO SPALMABILE (tipo Philadelphia)",
        "value": 193
    },
    {
        "label": "FRAGOLE",
        "value": 89
    },
    {
        "label": "FRUMENTO DURO",
        "value": 215
    },
    {
        "label": "FRUMENTO TENERO",
        "value": 216
    },
    {
        "label": "FRUTTA SCIROPPATA",
        "value": 115
    },
    {
        "label": "FRUTTA SECCA NS",
        "value": 125
    },
    {
        "label": "FUNGHI",
        "value": 23
    },
    {
        "label": "FUNGHI SOTT'OLIO",
        "value": 51
    },
    {
        "label": "GAMBERETTI SURGELATI",
        "value": 328
    },
    {
        "label": "GAMBERO",
        "value": 329
    },
    {
        "label": "GELATO AL CIOCCOLATO",
        "value": 367
    },
    {
        "label": "GELATO ALLA FRUTTA",
        "value": 368
    },
    {
        "label": "GELATO FIOR DI LATTE",
        "value": 369
    },
    {
        "label": "GHIACCIOLO ALL'ARANCIO",
        "value": 370
    },
    {
        "label": "GIARDINIERA SOTT'ACETO",
        "value": 52
    },
    {
        "label": "GIARDINIERA SOTT'OLIO",
        "value": 53
    },
    {
        "label": "GOMME DA MASTICARE",
        "value": 364
    },
    {
        "label": "GORGONZOLA",
        "value": 194
    },
    {
        "label": "GOUDA FRESCO",
        "value": 195
    },
    {
        "label": "GOUDA STAGIONATO",
        "value": 196
    },
    {
        "label": "GRANA",
        "value": 197
    },
    {
        "label": "GRANCHIO, POLPA IN SCATOLA",
        "value": 330
    },
    {
        "label": "GRANITA DI LIMONE",
        "value": 371
    },
    {
        "label": "GRISSINI",
        "value": 244
    },
    {
        "label": "GRISSINI INTEGRALI",
        "value": 245
    },
    {
        "label": "INDIVIA",
        "value": 28
    },
    {
        "label": "KIWI",
        "value": 90
    },
    {
        "label": "LAMPONI",
        "value": 91
    },
    {
        "label": "LARDO",
        "value": 344
    },
    {
        "label": "LATTE CONCENTRATO",
        "value": 174
    },
    {
        "label": "LATTE DI CAPRA",
        "value": 158
    },
    {
        "label": "LATTE DI COCCO",
        "value": 140
    },
    {
        "label": "LATTE DI MANDORLE, preparato da ricetta",
        "value": 141
    },
    {
        "label": "LATTE DI SOIA",
        "value": 75
    },
    {
        "label": "LATTE DI VACCA, CONDENSATO, CON ZUCCHERO",
        "value": 175
    },
    {
        "label": "LATTE DI VACCA, INTERO PASTORIZZATO",
        "value": 159
    },
    {
        "label": "LATTE DI VACCA, INTERO UHT",
        "value": 160
    },
    {
        "label": "LATTE DI VACCA, PARZIALMENTE SCREMATO PASTORIZZATO",
        "value": 161
    },
    {
        "label": "LATTE DI VACCA, PARZIALMENTE SCREMATO UHT",
        "value": 162
    },
    {
        "label": "LATTE DI VACCA, SCREMATO PASTORIZZATO",
        "value": 163
    },
    {
        "label": "LATTE DI VACCA, SCREMATO UHT",
        "value": 164
    },
    {
        "label": "LATTUGA",
        "value": 29
    },
    {
        "label": "LENTICCHIE IN SCATOLA",
        "value": 66
    },
    {
        "label": "LENTICCHIE, secche",
        "value": 67
    },
    {
        "label": "LIEVITO DI BIRRA, compresso",
        "value": 399
    },
    {
        "label": "LIMONE",
        "value": 92
    },
    {
        "label": "LIMONE, SCORZA",
        "value": 93
    },
    {
        "label": "LIQUIRIZIA DOLCE",
        "value": 365
    },
    {
        "label": "LUCCIO",
        "value": 291
    },
    {
        "label": "LUPINI DEAMARIZZATI",
        "value": 68
    },
    {
        "label": "MACADAMIA",
        "value": 126
    },
    {
        "label": "MAIONESE",
        "value": 398
    },
    {
        "label": "MAIS DOLCE IN SCATOLA",
        "value": 217
    },
    {
        "label": "MANDARANCI e CLEMENTINE",
        "value": 94
    },
    {
        "label": "MANDARINI",
        "value": 95
    },
    {
        "label": "MANDORLE",
        "value": 127
    },
    {
        "label": "MANGO",
        "value": 96
    },
    {
        "label": "MARGARINA SPALMABILE VEGETALE",
        "value": 334
    },
    {
        "label": "MARMELLATA DI FRUTTA",
        "value": 366
    },
    {
        "label": "MASCARPONE",
        "value": 198
    },
    {
        "label": "MELAGRANE",
        "value": 97
    },
    {
        "label": "MELANZANE",
        "value": 39
    },
    {
        "label": "MELANZANE SOTT'OLIO",
        "value": 54
    },
    {
        "label": "MELE GOLDEN, senza buccia",
        "value": 98
    },
    {
        "label": "MELE GRANNY SMITH, senza buccia",
        "value": 99
    },
    {
        "label": "MELE RENETTA, senza buccia",
        "value": 100
    },
    {
        "label": "MELE, disidratate",
        "value": 128
    },
    {
        "label": "MELE, senza buccia",
        "value": 101
    },
    {
        "label": "MELONE",
        "value": 102
    },
    {
        "label": "MERENDINE FARCITE",
        "value": 389
    },
    {
        "label": "MERENDINE TIPO PAN DI SPAGNA",
        "value": 373
    },
    {
        "label": "MERINGA",
        "value": 382
    },
    {
        "label": "MERLUZZO",
        "value": 292
    },
    {
        "label": "MERLUZZO SURGELATO, FILETTI",
        "value": 293
    },
    {
        "label": "MIELE",
        "value": 357
    },
    {
        "label": "MIRTILLI",
        "value": 103
    },
    {
        "label": "MORE",
        "value": 104
    },
    {
        "label": "MORTADELLA DI SUINO",
        "value": 261
    },
    {
        "label": "MOZZARELLA DI BUFALA",
        "value": 199
    },
    {
        "label": "MOZZARELLA DI VACCA",
        "value": 200
    },
    {
        "label": "MUESLI",
        "value": 232
    },
    {
        "label": "NOCCIOLE",
        "value": 129
    },
    {
        "label": "NOCE DI COCCO",
        "value": 105
    },
    {
        "label": "NOCI",
        "value": 131
    },
    {
        "label": "NOCI PECAN",
        "value": 130
    },
    {
        "label": "OLIO DI COCCO",
        "value": 335
    },
    {
        "label": "OLIO DI COLZA",
        "value": 336
    },
    {
        "label": "OLIO DI OLIVA",
        "value": 337
    },
    {
        "label": "OLIO DI OLIVA EXTRAVERGINE",
        "value": 338
    },
    {
        "label": "OLIO DI SEMI DI ARACHIDI",
        "value": 339
    },
    {
        "label": "OLIO DI SEMI DI GIRASOLE",
        "value": 340
    },
    {
        "label": "OLIO DI SEMI DI MAIS",
        "value": 341
    },
    {
        "label": "OLIO PER FRITTURA",
        "value": 342
    },
    {
        "label": "OLIVE",
        "value": 55
    },
    {
        "label": "OMBRINA, SURGELATA",
        "value": 294
    },
    {
        "label": "ORATA, SURGELATA",
        "value": 295
    },
    {
        "label": "ORZO, PERLATO",
        "value": 218
    },
    {
        "label": "OSTRICA",
        "value": 310
    },
    {
        "label": "PAN DI SPAGNA",
        "value": 384
    },
    {
        "label": "PANCARRE' AMERICANO COMUNE",
        "value": 247
    },
    {
        "label": "PANCETTA AFFUMICATA o BACON",
        "value": 262
    },
    {
        "label": "PANCETTA DI MAIALE",
        "value": 345
    },
    {
        "label": "PANE AL LATTE",
        "value": 248
    },
    {
        "label": "PANE COMUNE, pezzatura da 250g",
        "value": 249
    },
    {
        "label": "PANE DI GRANO DURO",
        "value": 250
    },
    {
        "label": "PANE DI SEGALE",
        "value": 251
    },
    {
        "label": "PANE GRATTUGIATO",
        "value": 252
    },
    {
        "label": "PANE INTEGRALE",
        "value": 253
    },
    {
        "label": "PANETTONE",
        "value": 374
    },
    {
        "label": "PANINI ALL'OLIO",
        "value": 254
    },
    {
        "label": "PANNA, 20% di lipidi (da cucina)",
        "value": 346
    },
    {
        "label": "PANNA, 30% di lipidi",
        "value": 347
    },
    {
        "label": "PANNA, 35% di lipidi",
        "value": 348
    },
    {
        "label": "PANNA, a basso contenuto di colesterolo",
        "value": 349
    },
    {
        "label": "PAPAYA",
        "value": 106
    },
    {
        "label": "PARMIGIANO",
        "value": 201
    },
    {
        "label": "PASTA ALL'UOVO, fresca",
        "value": 235
    },
    {
        "label": "PASTA ALL'UOVO, secca",
        "value": 236
    },
    {
        "label": "PASTA DI SEMOLA",
        "value": 237
    },
    {
        "label": "PASTA DI SEMOLA INTEGRALE",
        "value": 238
    },
    {
        "label": "PASTA FROLLA",
        "value": 385
    },
    {
        "label": "PASTA SFOGLIA",
        "value": 386
    },
    {
        "label": "PATATE",
        "value": 1
    },
    {
        "label": "PATATE, FECOLA",
        "value": 2
    },
    {
        "label": "PATATINE IN SACCHETTO",
        "value": 3
    },
    {
        "label": "PECORINO",
        "value": 202
    },
    {
        "label": "PEPERONI",
        "value": 40
    },
    {
        "label": "PEPERONI SOTT'ACETO",
        "value": 56
    },
    {
        "label": "PERA",
        "value": 107
    },
    {
        "label": "PESCA",
        "value": 108
    },
    {
        "label": "PESCE SPADA",
        "value": 296
    },
    {
        "label": "PESCHE, disidratate",
        "value": 132
    },
    {
        "label": "PINOLI",
        "value": 133
    },
    {
        "label": "PISELLI",
        "value": 69
    },
    {
        "label": "PISELLI IN SCATOLA",
        "value": 70
    },
    {
        "label": "PISELLI SURGELATI",
        "value": 71
    },
    {
        "label": "PISELLI, secchi",
        "value": 72
    },
    {
        "label": "PISTACCHI, freschi",
        "value": 134
    },
    {
        "label": "PISTACCHI, tostati e salati",
        "value": 135
    },
    {
        "label": "PIZZA CON POMODORO",
        "value": 255
    },
    {
        "label": "PIZZA CON POMODORO E MOZZARELLA",
        "value": 256
    },
    {
        "label": "POLENTA",
        "value": 227
    },
    {
        "label": "POLLO, ALA, con pelle",
        "value": 274
    },
    {
        "label": "POLLO, INTERO, con pelle",
        "value": 275
    },
    {
        "label": "POLLO, PETTO, senza pelle",
        "value": 276
    },
    {
        "label": "POLPO",
        "value": 311
    },
    {
        "label": "POLPO SURGELATO",
        "value": 312
    },
    {
        "label": "POMODORI",
        "value": 41
    },
    {
        "label": "POMODORI, secchi",
        "value": 57
    },
    {
        "label": "POMODORO, CONCENTRATO",
        "value": 42
    },
    {
        "label": "POMODORO, CONSERVA (sostanza secca 30%)",
        "value": 43
    },
    {
        "label": "POMPELMO",
        "value": 109
    },
    {
        "label": "POP CORN",
        "value": 233
    },
    {
        "label": "PORRI",
        "value": 21
    },
    {
        "label": "PROSCIUTTO COTTO",
        "value": 263
    },
    {
        "label": "PROSCIUTTO CRUDO",
        "value": 264
    },
    {
        "label": "PROVOLONE",
        "value": 203
    },
    {
        "label": "PRUGNE",
        "value": 110
    },
    {
        "label": "PRUGNE, secche",
        "value": 136
    },
    {
        "label": "PUREA DI MELA",
        "value": 116
    },
    {
        "label": "RADICCHIO",
        "value": 30
    },
    {
        "label": "RAPE",
        "value": 8
    },
    {
        "label": "RAVANELLI",
        "value": 9
    },
    {
        "label": "RIBES",
        "value": 113
    },
    {
        "label": "RIBES NERO",
        "value": 111
    },
    {
        "label": "RIBES ROSSO",
        "value": 112
    },
    {
        "label": "RICOTTA DI BUFALA",
        "value": 204
    },
    {
        "label": "RICOTTA DI PECORA",
        "value": 205
    },
    {
        "label": "RICOTTA DI VACCA",
        "value": 206
    },
    {
        "label": "RISO INTEGRALE, crudo",
        "value": 219
    },
    {
        "label": "RISO SOFFIATO",
        "value": 234
    },
    {
        "label": "RISO, BRILLATO",
        "value": 220
    },
    {
        "label": "RISO, TIPO PARBOILED",
        "value": 221
    },
    {
        "label": "ROBIOLA",
        "value": 207
    },
    {
        "label": "ROCHEFORT",
        "value": 208
    },
    {
        "label": "ROMBO",
        "value": 297
    },
    {
        "label": "RUCOLA",
        "value": 31
    },
    {
        "label": "SALAME",
        "value": 265
    },
    {
        "label": "SALE da cucina",
        "value": 404
    },
    {
        "label": "SALMONE",
        "value": 298
    },
    {
        "label": "SALMONE AFFUMICATO",
        "value": 323
    },
    {
        "label": "SALSICCIA",
        "value": 266
    },
    {
        "label": "SARDA",
        "value": 299
    },
    {
        "label": "SARDINE SOTT'OLIO, sgocciolato",
        "value": 317
    },
    {
        "label": "SCALOGNO",
        "value": 22
    },
    {
        "label": "SCAMORZA",
        "value": 209
    },
    {
        "label": "SCAMORZA AFFUMICATA",
        "value": 210
    },
    {
        "label": "SEDANO",
        "value": 44
    },
    {
        "label": "SEDANO RAPA",
        "value": 10
    },
    {
        "label": "SEMOLA",
        "value": 228
    },
    {
        "label": "SEPPIA",
        "value": 313
    },
    {
        "label": "SEPPIA SURGELATA",
        "value": 314
    },
    {
        "label": "SGOMBRO O MACCARELLO",
        "value": 300
    },
    {
        "label": "SGOMBRO, FILETTI SOTT'OLIO",
        "value": 318
    },
    {
        "label": "SOFFICINI AL FORMAGGIO SURGELATI",
        "value": 405
    },
    {
        "label": "SOGLIOLA",
        "value": 301
    },
    {
        "label": "SOGLIOLA SURGELATA",
        "value": 302
    },
    {
        "label": "SOIA, GERMOGLI",
        "value": 5
    },
    {
        "label": "SOIA, SEMI",
        "value": 73
    },
    {
        "label": "SOPRASSATA",
        "value": 267
    },
    {
        "label": "SOTTILETTE",
        "value": 211
    },
    {
        "label": "SPECK",
        "value": 268
    },
    {
        "label": "SPINACI",
        "value": 32
    },
    {
        "label": "SPREMUTA DI ARANCIA",
        "value": 142
    },
    {
        "label": "SPREMUTA DI POMPELMO, fresco",
        "value": 143
    },
    {
        "label": "STOCCAFISSO, ammollato",
        "value": 324
    },
    {
        "label": "STOCCAFISSO, secco",
        "value": 325
    },
    {
        "label": "STRACCHINO",
        "value": 212
    },
    {
        "label": "SUCCO DI ALBICOCCA",
        "value": 144
    },
    {
        "label": "SUCCO DI ANANAS, non zuccherato",
        "value": 145
    },
    {
        "label": "SUCCO DI ARANCIA E POMPELMO, non zuccherato",
        "value": 146
    },
    {
        "label": "SUCCO DI ARANCIA, concentrato",
        "value": 147
    },
    {
        "label": "SUCCO DI ARANCIA, non zuccherato",
        "value": 148
    },
    {
        "label": "SUCCO DI FRUTTA ACE",
        "value": 149
    },
    {
        "label": "SUCCO DI LIMONE",
        "value": 150
    },
    {
        "label": "SUCCO DI LIMONE, fresco",
        "value": 151
    },
    {
        "label": "SUCCO DI MELA, non zuccherato",
        "value": 152
    },
    {
        "label": "SUCCO DI MELAGRANA, fresco",
        "value": 153
    },
    {
        "label": "SUCCO DI PERA",
        "value": 154
    },
    {
        "label": "SUCCO DI PESCA",
        "value": 155
    },
    {
        "label": "SUCCO DI POMPELMO, zuccherato",
        "value": 156
    },
    {
        "label": "SUCCO TROPICALE",
        "value": 157
    },
    {
        "label": "SUINO, bistecca, senza grasso visibile",
        "value": 280
    },
    {
        "label": "TACCHINA, PETTO, con pelle",
        "value": 277
    },
    {
        "label": "TACCHINO, ALA, con pelle",
        "value": 278
    },
    {
        "label": "TACCHINO, FESA (PETTO), senza pelle",
        "value": 279
    },
    {
        "label": "TALEGGIO",
        "value": 213
    },
    {
        "label": "TARTUFO NERO",
        "value": 24
    },
    {
        "label": "TE' DETEINATO, in foglie",
        "value": 392
    },
    {
        "label": "TE' DETEINATO, in tazza",
        "value": 393
    },
    {
        "label": "TE', in tazza",
        "value": 394
    },
    {
        "label": "TEMPEH",
        "value": 76
    },
    {
        "label": "TINCA",
        "value": 303
    },
    {
        "label": "TIRAMISU'",
        "value": 380
    },
    {
        "label": "TOFU",
        "value": 77
    },
    {
        "label": "TONNO",
        "value": 304
    },
    {
        "label": "TONNO SOTT'OLIO, sgocciolato",
        "value": 319
    },
    {
        "label": "TORTELLINI, freschi",
        "value": 239
    },
    {
        "label": "TORTELLINI, secchi",
        "value": 240
    },
    {
        "label": "TRIGLIA",
        "value": 305
    },
    {
        "label": "TROTA",
        "value": 306
    },
    {
        "label": "UOVO DI GALLINA, ALBUME",
        "value": 331
    },
    {
        "label": "UOVO DI GALLINA, INTERO",
        "value": 332
    },
    {
        "label": "UVA",
        "value": 114
    },
    {
        "label": "UVA SULTANINA/UVETTA, UVA SECCA",
        "value": 137
    },
    {
        "label": "VALERIANA",
        "value": 33
    },
    {
        "label": "VONGOLA",
        "value": 315
    },
    {
        "label": "WURSTEL",
        "value": 269
    },
    {
        "label": "YOGURT CAPRINO",
        "value": 165
    },
    {
        "label": "YOGURT DI LATTE INTERO",
        "value": 166
    },
    {
        "label": "YOGURT DI LATTE INTERO ALLA FRUTTA ZUCCHERATO",
        "value": 167
    },
    {
        "label": "YOGURT DI LATTE INTERO ZUCCHERATO",
        "value": 168
    },
    {
        "label": "YOGURT DI LATTE PARZIALMENTE SCREMATO",
        "value": 169
    },
    {
        "label": "YOGURT DI LATTE SCREMATO",
        "value": 170
    },
    {
        "label": "YOGURT DI LATTE SCREMATO ALLA FRUTTA NON ZUCCHERATO",
        "value": 171
    },
    {
        "label": "YOGURT DI LATTE SCREMATO ALLA FRUTTA ZUCCHERATO",
        "value": 172
    },
    {
        "label": "YOGURT GRECO",
        "value": 173
    },
    {
        "label": "ZUCCA GIALLA",
        "value": 45
    },
    {
        "label": "ZUCCHERO (Saccarosio)",
        "value": 358
    },
    {
        "label": "ZUCCHERO DI CANNA, GREZZO",
        "value": 359
    },
    {
        "label": "ZUCCHINE",
        "value": 46
    }
]


