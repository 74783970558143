import { useState } from 'react';

import { Modal } from '../../../../components/Modal/Modal';
import { Input } from '../../../../components/Input/Input';
import { InputSelect } from '../../../../components/Input/InputSelect';
import { InputBeverageSelect } from '../../../../components/Input/InputBeverageSelect';
import { getOptionFromLabel } from '../../../../helpers/commonHelpers';
import { beverageValues, foods } from '../../../../utils/Constants';

import './AddFoodModal.scss';

interface Props {
    isOpen: boolean
    type: 'food' | 'beverage'
    onCloseButtonClick?: () => void
    handleFoodSave: (name: string, quantity: string, type: string) => void
}

export const AddFoodModal: React.FC<Props> = ({isOpen, type, onCloseButtonClick, handleFoodSave}) => {
    const [name, setName] = useState<string>('');
    const [quantity, setQuantity] = useState<string>('');
    const modalTitle = type === 'beverage' ? 'Nuova Bevanda' : 'Nuovo Alimento';

    const onCloseModal = () => {
        setName('');
        setQuantity('');
        onCloseButtonClick?.();
    }

    const onPrimaryButtonClick = (name: string, quantity: string, type: string) => {
        handleFoodSave(name, quantity, type);
        setName('');
        setQuantity('');
    }

    return <Modal
        className='mini add-food-modal'
        isOpen={isOpen}
        title={modalTitle}
        onCloseButtonClick={onCloseModal}
        primaryButtonText='Aggiungi'
        primaryButtonDisabled={!name || !quantity}
        onPrimaryButtonClick={() => onPrimaryButtonClick(name, quantity, type)}
    >
        <div className='form-wrapper'>
            {/* <Input
                label="Nome*"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            /> */}
            <InputSelect
                label='Nome*'
                name='name'
                value={getOptionFromLabel(foods, name)}
                // error={errors.gender?.message}
                placeholder="Seleziona alimento"
                options={foods}
                onChange={(option) => setName(option.label)}
            />
            <p className='text-underline'>Non sei riuscito a trovare l'alimento?</p>
            {type === 'food' &&
                <Input
                    label="Quantità* (in grammi)"
                    name="quantity"
                    type="number"
                    suffix="gr"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
            }
            {type === 'beverage' &&
                <InputBeverageSelect 
                    error={''}
                    options={beverageValues}
                    value={quantity}
                    onSelect={(option) => setQuantity(option.value)}
                />
            }
        </div>
    </Modal>
};