import Select from 'react-select'
import { FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form';

import { Input } from "./Input";
import { SelectOption } from '../../types/SelectOption';

interface Props {
    className?: string,
    disabled?: boolean,
    error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>,
    isMulti?: boolean,
    label: string,
    name: string,
    options?: any[],
    placeholder?: string,
    sourceDomain?: string,
    value?: SelectOption,
    getOptionLabel?: (option: any) => string,
    getOptionValue?: (option: any) => string,
    onChange?: (newValue: any) => void,
    register?: UseFormRegister<any>
}

export const InputSelect: React.FC<Props> = ({
    className, 
    disabled, 
    error, 
    isMulti, 
    label,
    name, 
    options,
    placeholder, 
    value,
    getOptionLabel, 
    getOptionValue, 
    onChange,
    register, 
}) => {

    return <Input
        name={name}
        label={label}
        className={className}
        register={register}
        error={error}
        type='select'
    >
        <Select 
            name={name}
            value={value}
            className="custom-select-wrapper"
            classNamePrefix="custom-select"
            getOptionLabel={getOptionLabel} 
            getOptionValue={getOptionValue}
            options={options}
            isMulti={isMulti} 
            isDisabled={disabled}
            placeholder={placeholder ? placeholder : 'Seleziona...'}
            {...register?.(name)}
            onChange={onChange}
        />
    </Input>

};
