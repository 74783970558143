import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { setDefaultOptions } from "date-fns";
import { it } from 'date-fns/locale'

import { DeleteModal } from "../../components/Modal/DeleteModal";
import { MealCardsSkeleton } from "../../components/Skeletons/MealCardsSkeleton";

import { notifyErrorMessage, notifySuccessMessage } from "../../helpers/commonHelpers";

import { addUserChildren, getLocalUser, getUserChildrens } from "../../services/UserService";
import { logout } from "../../services/AuthService";
import { deleteChildren } from "../../services/ChildrenService";

import { Children } from "../../types/Children";
import { User } from "../../types/User";

import { ChildrenCard } from "./ChildrenCard/ChildrenCard";

import { AddChildrenCard } from "./ChildrenCard/AddChildrenCard";
import { AddChildrenModal } from "./ChildrenModals/AddChildrenModal";
import { EditChildrenModal } from "./ChildrenModals/EditChildrenModal";
import './DashboardSelectChildrenPage.scss';

function DashboardSelectChildrenPage() {
    const navigate = useNavigate();
    setDefaultOptions({ locale: it })
    const [childrens, setChildrens] = useState<Children[]>([]);
    const [childrenToEdit, setChildrenToEdit] = useState<Children | null>(null);
    const [childrenToDelete, setChildrenToDelete] = useState<Children | null>(null);
    const [isLoadingChildrens, setIsLoadingChildrens] = useState(true);
    const [isChildrenSaveModalOpen, setIsChildrenSaveModalOpen] = useState(false);
    const localUser: User = getLocalUser();

    useEffect(() => {
        fetchUserChildrens();
    }, [])

    const fetchUserChildrens = async () => {
        if(localUser && localUser.id) {
            await getUserChildrens(localUser.id)
                .then(userChildrens => {
                    setChildrens(userChildrens);
                })
                .catch(error => {})
                .finally(() => {
                    setIsLoadingChildrens(false);
                });
        } else {
            logout();
        }
    };

    const handleChildrenSaved = async (children: Children) => {
        setChildrens(prevChildrens => [...prevChildrens, children])
        setIsChildrenSaveModalOpen(false);
    }

    const handleChildrenUpdated = async (updatedChildren: Children) => {
        setChildrens(prevChildrens =>
            prevChildrens.map(children =>
                children.id === updatedChildren.id ? updatedChildren : children
            )
        );
        setChildrenToEdit(null);
        notifySuccessMessage('I dati del/della bambino/a sono stati aggiornati correttamente');
    }

    const handleChildrenEdit = async (children: Children) => {
        const localUser: User = getLocalUser();
        if(localUser && localUser.id) {
            addUserChildren(localUser.id, children)
                .then(childrenAdded => {
                    setChildrens(prevChildrens => [...prevChildrens, childrenAdded])
                })
                .catch(error => {
                    notifyErrorMessage('Qualcosa è andato storto. Riprova più tardi.');
                })
                .finally(() => setIsChildrenSaveModalOpen(false));
        }
    }

    const handleChildrenDelete = async (children: Children) => {
        if(!children.id) return;
        deleteChildren(children.id)
            .then(childrenDeleted => {
                setChildrens((prevChildrens) => prevChildrens.filter((c) => c.id !== childrenDeleted.id))
                notifySuccessMessage(`Il/la bambino/a ${children.name} è stato eliminato correttamente`);
            })
            .catch(error => {
                notifyErrorMessage('Qualcosa è andato storto. Riprova più tardi.');
            })
            .finally(() => setChildrenToDelete(null));
    }

    return <div className="dashboard-select-children-page">
        <div className='hero'>
            <h1 className='title'>Diario Alimentare</h1>
            <p className='subtitle'>Seleziona o aggiungi il/la bambino/a per il quale vuoi compilare il Diario Alimentare</p>
        </div>
        <div className='content'>
            {isLoadingChildrens ?
                <MealCardsSkeleton /> :
                <div className="childrens-cards-wrapper">
                    {childrens.map(children => 
                        <ChildrenCard 
                            key={children.id}
                            children={children}
                            onClick={() => navigate(`/diario-alimentare/${children.id}`)}
                            onEditButtonClick={() => setChildrenToEdit(children)}
                            onDeleteButtonClick={() => setChildrenToDelete(children)}
                        />
                    )}
                    <AddChildrenCard 
                        onClick={() => setIsChildrenSaveModalOpen(true)}
                    />
                </div>
            }
        </div>
        <AddChildrenModal 
            isOpen={isChildrenSaveModalOpen}
            onCloseButtonClick={() => setIsChildrenSaveModalOpen(false)}
            onSuccess={handleChildrenSaved}
        />
        {childrenToEdit &&
            <EditChildrenModal
                childrenToEdit={childrenToEdit}
                isOpen={childrenToEdit != null}
                onCloseButtonClick={() => setChildrenToEdit(null)}
                onSuccess={handleChildrenUpdated}
            />
        }
        {childrenToDelete &&
            <DeleteModal
                isOpen={childrenToDelete != null}
                onCloseButtonClick={() => setChildrenToDelete(null)}
                onPrimaryButtonClick={() => handleChildrenDelete(childrenToDelete)}
            >
                <div className="message-wrapper">
                    <p>Sei sicuro di voler eliminare <b>{childrenToDelete?.name}</b>?</p>
                    <p>Tutti i dati del suo Diario Alimentare saranno eliminati</p>
                </div>
            </DeleteModal>
        }
    </ div>
}

export default DashboardSelectChildrenPage;