import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Button } from '../../components/Button/Button';
import { InputPassword } from '../../components/Input/InputPassword';

import { notifyErrorMessage, notifySuccessMessage } from '../../helpers/commonHelpers';

import Logo from "../../images/logo-fun-vegetables.png";

import * as Auth from "../../services/AuthService";
import { setLocalUser } from '../../services/UserService';

function PasswordResetPage() {
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const email = query.get('email');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const resetPassword = () => {
        setIsLoading(true);
        setErrorMessage('');
        Auth.resetPassword(password, email, token)
            .then(response => {
                notifySuccessMessage('La password è stata resettata con successo!')
                setTimeout(() => {
                    navigate('/accedi');
                }, 7000);
            })  
            .catch(error => {
                notifyErrorMessage(error.message);
            })
            .finally(() => setIsLoading(false));
    }

    return (  
        <div className="login-page">
            <div className='content-wrapper'>
                <div className="logo-section">
                    <img className='logo-image' src={Logo} alt="Logo Fun Vegetables" />
                </div>
                <div className="form-section">
                    <div className='form-title'>
                        <p className='font-bold title'>Aggiorna la password</p>
                        <p className='subtitle'>Inserisci una nuova password e clicca sul tasto invia</p>
                        </div>
                    <div className='form'>
                        <InputPassword
                            label='Password*'
                            name='password'
                            placeholder='Inserisci la tua password'
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                        <InputPassword
                            label='Conferma password*'
                            name='confirmPassword'
                            placeholder='Inserisci la tua password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                        />
                        {errorMessage &&
                            <div className='error-message'>
                                <p>{errorMessage}</p>
                            </div>
                        }
                        <div className='button-wrapper'>
                            <Button 
                                label="Invia"
                                className="primary-button"
                                onClick={resetPassword}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer limit={1}></ToastContainer>
        </div>
    );
}

export default PasswordResetPage;