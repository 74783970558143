import { Children } from "../types/Children";
import { Meal } from "../types/Meal";
import { RegistrationForm } from "../types/RegistrationForm";
import { Training } from "../types/Training";
import { User } from "../types/User";
import { Water } from "../types/Water";

import apiClient, { buildRequestConfig } from "./apiClient";

export const getUser = async (): Promise<any> => {
    const config = buildRequestConfig();
    return await apiClient.get('/user', config)
        .then((response) => {
            const responseData = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const updateUser = async (updatedUser: RegistrationForm): Promise<any> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/users/${updatedUser.id}`;
    return await apiClient.put(reuqestUrl, updatedUser, config)
        .then((response) => {
            if(response.status === 200) {
                const responseData = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const updatePassword = async (oldPassword: string, newPassword: string): Promise<any> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/user/update-password`;
    const requestData = {
        oldPassword: oldPassword,
        newPassword: newPassword,

    };
    return await apiClient.post(reuqestUrl, requestData, config)
        .then((response) => {
            console.log(response);
            if(response.status === 200) {
                const responseData = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const setLocalUser = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
};

export const getLocalUser = (): User => {
    const userString = localStorage.getItem('user');
    if(userString) return JSON.parse(userString);
    return {}
};

export const removeLocalUser = () => {
    localStorage.removeItem('user');
};

export const getUserChildrens = async (userId: string): Promise<any> => {
    const config = buildRequestConfig();
    return await apiClient.get(`/users/${userId}/childrens`, config)
        .then((response) => {
            const responseData = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const addUserChildren = async (userId: string, children: Children): Promise<any> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/users/${userId}/childrens`;
    return await apiClient.post(reuqestUrl, children, config)
        .then((response) => {
            if(response.status === 201) {
                const responseData = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            console.log(error);
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

// export const getUserMeals = async (userId: string, date: string): Promise<any> => {
//     const config = buildRequestConfig();
//     return await apiClient.get(`/users/${userId}/meals?date=${date}`, config)
//         .then((response) => {
//             const responseData = response.data;
//             return responseData;
//         })
//         .catch(error => {
//             if(error.response && error.response.data.message) {
//                 throw new Error(error.response.data.message);
//             }
//             throw new Error('Qualcosa è andato storto. Riprova');
//         }); 
// }

// export const addUserMeal = async (userId: string, meal: Meal): Promise<any> => {
//     const config = buildRequestConfig();
//     const reuqestUrl = `/users/${userId}/meals`;
//     return await apiClient.post(reuqestUrl, meal, config)
//         .then((response) => {
//             if(response.status === 201) {
//                 const responseData = response.data;
//                 return responseData;
//             } else {
//                 throw new Error('Qualcosa è andato storto. Riprova');
//             }
//         })
//         .catch(error => {
//             console.log(error);
//             if(error.response && error.response.data.message) {
//                 throw new Error(error.response.data.message);
//             }
//             throw new Error('Qualcosa è andato storto. Riprova');
//         }); 
// }

// export const getUserWater = async (userId: string, date: string): Promise<Water> => {
//     const config = buildRequestConfig();
//     return await apiClient.get(`/users/${userId}/waters?date=${date}`, config)
//         .then((response) => {
//             const responseData = response.data;
//             return responseData;
//         })
//         .catch(error => {
//             if(error.response && error.response.data.message) {
//                 throw new Error(error.response.data.message);
//             }
//             throw new Error('Qualcosa è andato storto. Riprova');
//         }); 
// }

// export const addUserWater = async (userId: string, water: Water): Promise<Water> => {
//     const config = buildRequestConfig();
//     const reuqestUrl = `/users/${userId}/waters`;
//     return await apiClient.post(reuqestUrl, water, config)
//         .then((response) => {
//             if(response.status === 201 || response.status === 200) {
//                 const responseData = response.data;
//                 return responseData;
//             } else {
//                 throw new Error('Qualcosa è andato storto. Riprova');
//             }
//         })
//         .catch(error => {
//             console.log(error);
//             if(error.response && error.response.data.message) {
//                 throw new Error(error.response.data.message);
//             }
//             throw new Error('Qualcosa è andato storto. Riprova');
//         }); 
// }

// export const getUserTraining = async (userId: string, date: string): Promise<Water> => {
//     const config = buildRequestConfig();
//     return await apiClient.get(`/users/${userId}/trainings?date=${date}`, config)
//         .then((response) => {
//             const responseData = response.data;
//             return responseData;
//         })
//         .catch(error => {
//             if(error.response && error.response.data.message) {
//                 throw new Error(error.response.data.message);
//             }
//             throw new Error('Qualcosa è andato storto. Riprova');
//         }); 
// }

// export const addUserTraining = async (userId: string, training: Training): Promise<any> => {
//     const config = buildRequestConfig();
//     const reuqestUrl = `/users/${userId}/trainings`;
//     return await apiClient.post(reuqestUrl, training, config)
//         .then((response) => {
//             if(response.status === 201 || response.status === 200) {
//                 const responseData = response.data;
//                 return responseData;
//             } else {
//                 throw new Error('Qualcosa è andato storto. Riprova');
//             }
//         })
//         .catch(error => {
//             console.log(error);
//             if(error.response && error.response.data.message) {
//                 throw new Error(error.response.data.message);
//             }
//             throw new Error('Qualcosa è andato storto. Riprova');
//         }); 
// }
