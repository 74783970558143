import { useState } from 'react';

import { Modal } from '../../../components/Modal/Modal';
import { Input } from '../../../components/Input/Input';

import { notifyErrorMessage } from '../../../helpers/commonHelpers';

import { updateChildren } from '../../../services/ChildrenService';

import { Children } from '../../../types/Children';

interface Props {
    childrenToEdit: Children
    isOpen: boolean
    onCloseButtonClick?: () => void
    onSuccess: (children: Children) => void
}

export const EditChildrenModal: React.FC<Props> = ({childrenToEdit, isOpen, onCloseButtonClick, onSuccess}) => {
    const [children, setChildren] = useState<Children>(childrenToEdit);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChildrenChange = (name: keyof Children, value: string) => {
        setChildren(prevChildren => ({
            ...prevChildren,
            [name]: value,
        }));
    };

    const handleChildrenUpdate = async (children: Children) => {
        setIsLoading(true);
        updateChildren(children)
            .then(childrenUpdated => {
                onSuccess(childrenUpdated);
            })
            .catch(error => {
                notifyErrorMessage('Non siamo riusciti ad aggiornare i dati. Riprova più tardi.');
            })
            .finally(() => setIsLoading(false))
    }

    const onCloseModal = () => {
        onCloseButtonClick?.();
    }

    const onPrimaryButtonClick = () => {
        handleChildrenUpdate(children);
    }

    return <Modal
        className='mini add-children-modal'
        isOpen={isOpen}
        title="Modifica Bambino/a"
        onCloseButtonClick={onCloseModal}
        primaryButtonText='Aggiorna'
        primaryButtonLoading={isLoading}
        primaryButtonDisabled={!children.name || isLoading}
        onPrimaryButtonClick={onPrimaryButtonClick}
    >
        <div className='form-wrapper'>
            <Input
                label="Nome*"
                name="name"
                placeholder='Inserisci il nome'
                type="text"
                value={children.name}
                onChange={(e) => handleChildrenChange('name', e.target.value)}
            />
            <Input
                label="Peso"
                name="weight"
                placeholder='Inserisci il tuo peso'
                suffix="kg"
                type="number"
                value={children.weight ? children.weight : ''}
                onChange={(e) => handleChildrenChange('weight', e.target.value)}
            />
            <Input
                label="Altezza*"
                name="height"
                placeholder='Inserisci la tua altezza'
                suffix="cm"
                type="number"
                value={children.height ? children.height : ''}
                onChange={(e) => handleChildrenChange('height', e.target.value)}
            />
        </div>
    </Modal>
};