import { Button } from '../../../components/Button/Button';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { Children } from '../../../types/Children';
import './ChildrenCard.scss';

interface Props {
    children: Children
    onClick: (children: Children) => void,
    onEditButtonClick: (children: Children) => void,
    onDeleteButtonClick: (children: Children) => void,
}

export const ChildrenCard: React.FC<Props> = ({children, onClick, onEditButtonClick, onDeleteButtonClick}) => { 
    const {name, weight, height} = children;
    
    return <div className="children-card">
        {/* <div className='img'>
            <img src={''} alt='' />
        </div> */}
        <div className='icons'>
            <span className='edit-icon' onClick={() => onEditButtonClick(children)}>
                <EditIcon />
            </span>
            <span className='delete-icon' onClick={() => onDeleteButtonClick(children)}>
                <DeleteIcon />
            </span>
        </div>
        <div className='text'>
            <p className="font-bold text-large name">{name}</p>
            <p className="text-xs subtitle"><b>Peso:</b> {weight ? `${weight} Kg` : 'n/a'}</p>
            <p className="text-xs subtitle"><b>Altezza:</b> {height ? `${height} cm` : 'n/a'}</p>
        </div>
        <p className='link-button' onClick={() => onClick(children)}>
            Vai al Diario di {children.name}
        </p> 
    </div>
};