import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getUserToken } from "./TokenService"; // Assumo che la funzione logout sia qui
import { logout } from "./AuthService";

const url = 'https://apifunv.danieletotaro.com/api';
// const url = 'http://localhost:81/api';

const defaultConfig: AxiosRequestConfig = {
    baseURL: url,
};

export const buildRequestConfig = (): AxiosRequestConfig => {
    const bearerToken = getUserToken();
    let requestConfig: AxiosRequestConfig = {
        headers: {
            Accept: "application/json",
            'content-type': 'application/json'
        }
    };
    if (bearerToken != '') {
        requestConfig.headers!.Authorization = 'Bearer ' + bearerToken;
    }
    return requestConfig;
};

const apiClient: AxiosInstance = axios.create(defaultConfig);

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            logout();
        }
        return Promise.reject(error);
    }
);

export default apiClient;